export default {
    NOT_FOUND: '/404',
    HOME: '',
    SEARCH: '/search',
    TIRE: '/tire',
    TIRE_BY_CAR: '/tire-by-car-model',
    TIRE_BY_TYPE: '/tire-by-type',
    TIRE_BRAND: '/brand/:brand',
    TIRE_DETAIL: '/tire/:id',
    REVIEW_ALL: '/review',
    PROMOTION: '/promotion',
    PROMOTION_DETAIL: '/promotion/:key',
    ARTICLE: '/article',
    ARTICLE_DETAIL: '/article/:key',
    STORE: '/store',
    STORE_BY_TIRE_REPAIR: '/store-tire-repair',
    STORE_DETAIL: '/store/:id',
    STORE_DETAIL_WITH_PRODUCT: '/store/:id/:spid',
    NETWORK: '/network',
    NETWORK_DETAIL: '/network/:key',
    NETWORK_DETAIL_WITH_PRODUCT: '/network/:key/:pid',
    CART: '/cart',
    RECEIPT: '/order/receipt/:id',
    ABOUT: '/about',
    CONTACT: '/contact',
    TIRE_CALCULATOR: '/tire-calculator',
    LOGIN: '/login',
    REGISTER: '/register',
    FORGET_PASSWORD: '/forget-password',
    AUTHOR_PROFILE: '/author/:id',
    MY_BASE: '/my',
    MY_FAVORITE: '/my/favorite',
    MY_ORDER: '/my/order',
    MY_ORDER_REVIEW: '/my/order/review',
    MY_RECEIPT: '/my/receipt/:id',
    MY_RECEIPT_FEE: '/my/receipt-fee/:id',
    MY_PROFILE: '/my/profile',
    MY_PASSWORD: '/my/password',
    MY_CAR: '/my/car',
    MY_PRIVACY: '/my/privacy',
    SELLER_MAIN: '/seller',
    SELLER_REGISTER: '/seller/register',
    SELLER_LOGIN: '/seller/login',
    SELLER_FORGET_PASSWORD: '/seller/forget-password',
    SELLER_FORM: '/seller/form',
    SELLER_PROFILE: '/seller/profile',
    SELLER_SETTING: '/seller/setting',
    SELLER_SETTING_PREPAID: '/seller/setting-prepaid',
    SELLER_STAFF: '/seller/staff',
    SELLER_DECORATION: '/seller/decoration',
    SELLER_ORDERS: '/seller/order/manage',
    SELLER_ORDERS_SERVICE: '/seller/order/service',
    SELLER_ORDERS_DELIVERY: '/seller/order/delivery',
    SELLER_ORDER_DETAIL: '/seller/order/detail/:id',
    SELLER_RECEIPT: '/seller/order/receipt/:id',
    SELLER_DELIVERY_COVER: '/seller/order/delivery-cover/:id',
    SELLER_PRODUCTS: '/seller/product/manage',
    SELLER_PRODUCTS_SERVICE: '/seller/product/service',
    SELLER_PRODUCTS_WARRANTY: '/seller/product/warranty',
    SELLER_PRODUCTS_PROMOTION: '/seller/product/promotion',
    SELLER_PRODUCTS_PROMOTION_V2: '/seller/product/promotion/v2',
    SELLER_PRODUCTS_PROMOTION_DETAIL: '/seller/product/promotion/v2/:id',
    SELLER_PRODUCTS_IMPORT: '/seller/product/import',
    SELLER_DASHBOARD: '/seller/dashboard',
    SELLER_REPORT_SALE: '/seller/report/sale',
    SELLER_REPORT_PRODUCT_REMAIN: '/seller/report/product-remain',
    SELLER_REPORT_PROMOTION: '/seller/report/promotion',
    SELLER_REPORT_PRODUCT_PROMOTION: '/seller/report/product-promotion',
    SELLER_REPORT_PRODUCT_MOVEMENT: '/seller/report/product-movement',
    SELLER_REPORT_SYSTEM: '/seller/report/system',
    SELLER_REPORT_CUSTOMER: '/seller/report/customer',
    SELLER_INCOME: '/seller/income',
    SELLER_REVIEW: '/seller/review',
    SELLER_HOLIDAY: '/seller/holiday',
    STATIC_TERM: '/help/term',
    STATIC_TERM_SELL_PRODUCT: '/help/term-sell-product',
    STATIC_TERM_WARRANTY: '/help/term-warranty',
    STATIC_TERM_STORE: '/help/term-store',
    STATIC_INFO_TSI: '/help/info-tsi',
    STATIC_INFO_EU: '/help/info-eu',
    STATIC_PRIVACY: '/help/privacy',
    STATIC_COOKIE: '/help/cookie',
    STATIC_SELLER_TERM_INSTALLATION: '/help/term-seller-installation',
    STATIC_SELLER_TERM_DELIVERY: '/help/term-seller-delivery',
};
